import React, { FC, useEffect, useState } from "react";
import { Menu, MenuItem, Toolbar, MenuList } from "@material-ui/core";
import { Expand } from "~/assets/svg";
import { PrimeLogo } from "~/assets/img";
import { Path } from "~/routes/routes.path";
import { device } from "~/theme/breakpoints";
import { observer, onRedirect, useStores } from "~/utils";
import If from "../If";
import Variables from "../Variables";
import { Amplify } from "~/services";
import {
  Content,
  ImgIcon,
  NameUser,
  SectionIcon,
  SectionLogo,
  SectionToolbar,
  TextProduct,
} from "./styles";
import { verify } from "crypto";

type Props = {
  title?: string;
  file?: string;
  hideNewMethod?: boolean;
  hiddenButtons?: boolean;
  contactButton?: boolean;
  processingText?: string;
  onProcess?: () => void;
};

const amplify = new Amplify();

const Header: FC<Props> = ({
  title,
  file,
  hideNewMethod,
  hiddenButtons,
  processingText = "Processar todos",
  onProcess,
  contactButton = false,
}) => {
  const { routing, formulation } = useStores();
  const [modalVariables, setModalVariables] = useState(false);
  const [toolbarOpened, setToolbar] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const getUsername = async () => {
      const { username } = await amplify.getUsername();
      setUsername(username);
    };
    getUsername();
  }, [username]);

  const handleToolbar = () => {
    setToolbar(!toolbarOpened);
  };

  const onLogout = async () => {
    await amplify.logout();
    routing.replace(Path.LOGIN);
  };

  const onVariables = () => setModalVariables(!modalVariables);

  const onHome = () => routing.replace(Path.HOME);
  const onSSOHome = () => routing.replace(Path.SSOHOME);

  const onClearMethods = () => formulation.onClearMethods();

  const { length } = formulation.selected;

  return (
    <>
    
      <Content>
        <SectionLogo>
          <ImgIcon src={PrimeLogo} title='Home' onClick={onSSOHome}/>
          <TextProduct>{title}</TextProduct>
        </SectionLogo>
        <SectionToolbar>
          <SectionIcon onClick={handleToolbar}>
            <If condition={!device.isMobile}>
              <NameUser>{username}</NameUser>
            </If>
            <Expand />
          </SectionIcon>
          <If condition={toolbarOpened}>
            <Toolbar 
              onClick={handleToolbar}
              style={{ position: "absolute", right: 0, top: 60 }}
            >
              <MenuList
                style={{ width: 150 , backgroundColor: "#fff", borderRadius: 5, right: 0 }}
              >
                <MenuItem
                  style={{ width: 150 }} 
                  onClick={onLogout}>
                  Sair
                </MenuItem>
              </MenuList>
            </Toolbar>
          </If>
        </SectionToolbar>
      </Content>
      <Variables visible={modalVariables} onClose={onVariables} />
    </>
  );
};

export default observer(Header);
